var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-promotion-user-detail",
      "title": _vm.$t('promotionUser.detail.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "hide-footer": "",
      "lazy": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.handleOpenModal
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !!_vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          pristine = _ref.pristine;
        return [_vm.promotionDataToUpdate ? _c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submitHandle);
            }
          }
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionUser.fields.employeeCode'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionUser.fields.employeeCode')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "formatter": _vm.upperCaseFormatter,
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('promotionUser.placeholder.employeeCode'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToUpdate.employeeCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToUpdate, "employeeCode", $$v);
                  },
                  expression: "promotionDataToUpdate.employeeCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionUser.fields.source'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionUser.fields.source')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticStyle: {
                  "font-size": "1rem"
                },
                attrs: {
                  "options": _vm.distributorExtendOptions,
                  "clearable": false,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('promotionUser.placeholder.source')
                },
                on: {
                  "close": validationContext.validate
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: " d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref2) {
                    var loading = _ref2.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.promotionDataToUpdate.source,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToUpdate, "source", $$v);
                  },
                  expression: "promotionDataToUpdate.source"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionUser.fields.maxApply'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionUser.fields.maxApply')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "maxlength": "5",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionUser.placeholder.maxApply'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToUpdate.maxApply,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToUpdate, "maxApply", $$v);
                  },
                  expression: "promotionDataToUpdate.maxApply"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionUser.fields.promoCode'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionUser.fields.promoCode')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "formatter": _vm.upperCaseFormatter,
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('promotionUser.placeholder.promoCode'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToUpdate.promoCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToUpdate, "promoCode", $$v);
                  },
                  expression: "promotionDataToUpdate.promoCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "mx-50",
          attrs: {
            "variant": "flat-danger",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('b-button', {
          staticClass: "mx-50",
          attrs: {
            "variant": _vm.readonly ? 'info' : 'outline-info',
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.toggleEdit
          }
        }, [_vm._v(" " + _vm._s(_vm.readonly ? _vm.$t('edit') : _vm.$t('cancelEdit')) + " ")]), !_vm.readonly ? _c('b-button', {
          staticClass: "mx-50",
          attrs: {
            "variant": "info",
            "disabled": pristine,
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }