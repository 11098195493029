<template>
  <b-modal
    id="modal-promotion-user-detail"
    :title="$t('promotionUser.detail.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    hide-footer
    lazy
    size="lg"
    no-close-on-backdrop
    @show="handleOpenModal"
  >
    <IAmOverlay :loading="!!loading">
      <validation-observer
        #default="{ handleSubmit, pristine }"
        ref="refFormObserver"
      >
        <!-- SECTION Form -->
        <b-form
          v-if="promotionDataToUpdate"
          @submit.prevent="handleSubmit(submitHandle)"
        >
          <b-row class="mt-1">
            <!-- ANCHOR Employee Code -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('promotionUser.fields.employeeCode')"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionUser.fields.employeeCode') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotionDataToUpdate.employeeCode"
                    :formatter="upperCaseFormatter"
                    :disabled="readonly"
                    :placeholder="$t('promotionUser.placeholder.employeeCode')"
                    :state="getValidationState(validationContext) === false ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Source -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('promotionUser.fields.source')"
                rules="required"
              >
                <b-form-group
                  class="mb-0"
                  :state="getValidationState(validationContext) === false ? false : null"
                >
                  <template #label>
                    <div>
                      {{ $t('promotionUser.fields.source') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToUpdate.source"
                    style="font-size: 1rem;"
                    :options="distributorExtendOptions"
                    :clearable="false"
                    :reduce="val => val.value"
                    :disabled="readonly"
                    :placeholder="$t('promotionUser.placeholder.source')"
                    @close="validationContext.validate"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span
                          class="d-block text-nowrap"
                        >
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template v-slot:option="data">
                      <div class="d-flex-center justify-content-between">
                        <span
                          class=" d-block text-nowrap"
                        >
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div
                        v-if="loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- ANCHOR maxApply -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('promotionUser.fields.maxApply')"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionUser.fields.maxApply') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotionDataToUpdate.maxApply"
                    v-remove-non-numeric-chars.allNumber
                    number
                    maxlength="5"
                    lazy-formatter
                    :disabled="readonly"
                    :formatter="trimInput"
                    :placeholder="$t('promotionUser.placeholder.maxApply')"
                    :state="getValidationState(validationContext) === false ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR promoCode -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('promotionUser.fields.promoCode')"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionUser.fields.promoCode') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotionDataToUpdate.promoCode"
                    :formatter="upperCaseFormatter"
                    :disabled="readonly"
                    :placeholder="$t('promotionUser.placeholder.promoCode')"
                    :state="getValidationState(validationContext) === false ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- !SECTION -->

          <!-- ANCHOR Action Buttons -->
          <div class="d-flex mt-1 justify-content-center">
            <b-button
              variant="flat-danger"
              class="mx-50"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="closeModal"
            >
              {{ $t('close') }}
            </b-button>

            <!-- Next button -->
            <b-button
              :variant="readonly ? 'info' : 'outline-info'"
              class="mx-50"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="toggleEdit"
            >
              {{ readonly ? $t('edit') : $t('cancelEdit') }}
            </b-button>

            <!-- Next button -->
            <b-button
              v-if="!readonly"
              variant="info"
              class="mx-50"
              :disabled="pristine"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              {{ $t('save') }}
            </b-button>
          </div>
        </b-form>
      <!-- !SECTION -->
      </validation-observer>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { distributorExtendOptions } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import usePromotionUserHandle from '@promotionUser/usePromotionUserHandle'

export default {
  name: 'PromotionUserEdit',
  components: {
    BModal,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    promotionData: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      updatePromotionUser,
      loading,
    } = usePromotionUserHandle()
    const promotionDataToUpdate = ref(null)
    const readonly = ref(true)

    const resetPromotionData = () => {
      promotionDataToUpdate.value = cloneDeep({
        id: props?.promotionData?.id,
        employeeCode: props?.promotionData?.employee?.employeeCode,
        source: props?.promotionData?.source,
        promoCode: props?.promotionData?.promoCode,
        maxApply: props?.promotionData?.maxApply,
      })
    }
    const { refFormObserver, getValidationState } = formValidation(resetPromotionData)

    watch(props, () => {
      resetPromotionData()
    })

    function handleOpenModal() {
      readonly.value = true
    }

    function submitHandle() {
      refFormObserver.value.validate().then(success => {
        if (success) {
          updatePromotionUser(promotionDataToUpdate.value)
            .then(() => {
              emit('refetch-data')
              this.$bvModal.hide('modal-promotion-user-detail')
            })
        }
      })
    }

    function closeModal() {
      this.$bvModal.hide('modal-promotion-user-detail')
    }

    function toggleEdit() {
      readonly.value = !readonly.value
      if (readonly.value) {
        resetPromotionData()
      }
    }

    return {
      submitHandle,
      trimInput,
      upperCaseFormatter,
      distributorExtendOptions,
      loading,
      getValidationState,
      promotionDataToUpdate,
      closeModal,
      readonly,
      toggleEdit,
      refFormObserver,
      handleOpenModal,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
